import { gql } from '@apollo/client';

export const CREATE_A_DISCLOSER = gql(`
mutation CreateDiscloserList($createDiscloserListInput: CreateDiscloserListInput!) {
  createDiscloserList(createDiscloserListInput: $createDiscloserListInput) {
    discloserListId
  }
}
`);

export const REMOVE_A_DISCLOSER = gql(`
mutation DeleteDemoList($authorityTypeId: ID!) {
  deleteDemoList(authorityTypeId: $authorityTypeId) {
    isDeleted
  }
}
`);

export const UPDATE_ORG_PARTICIPATION_INTENTION = gql(`
mutation UpdateConfirmIntention($confirmIntention: Boolean!) {
  updateConfirmIntention(confirmIntention: $confirmIntention) {
    confirmIntention
  }
}
`);

export const COPY_DISCLOSER_LIST = gql(`
mutation CopyDiscloserList($sourceDiscloserListId: ID!, $targetDiscloserListId: ID!) {
  copyDiscloserList(sourceDiscloserListId: $sourceDiscloserListId, targetDiscloserListId: $targetDiscloserListId) {
    success
  }
}
`);

export const ADD_DISCLOSER = gql(`
mutation AddToDiscloserList($discloserListId: ID!, $organisationId: ID!, $authorityTypeId: ID!, $noScoreAck: Boolean) {
  addToDiscloserList(discloserListId: $discloserListId, organisationId: $organisationId, authorityTypeId: $authorityTypeId, noScoreAck: $noScoreAck) {
    success
  }
}
`);

export const UPDATE_LATE_REQUEST = gql`
  mutation UpdateLateRequest($discloserListOrganisationLateRequestId: ID!, $status: Boolean!) {
    updateLateRequestResponse(
      discloserListOrganisationLateRequestId: $discloserListOrganisationLateRequestId
      status: $status
    ) {
      success
    }
  }
`;

export const REMOVE_DISCLOSER = gql(`
mutation RemoveDiscloserFromList($discloserListId: ID!, $organisationIds: [ID!]!) {
  removeDiscloserFromList(discloserListId: $discloserListId, organisationIds: $organisationIds) {
    success
  }
}
`);

export const REMOVE_LATE_REQUEST_DISCLOSER = gql(`
  mutation RemoveDiscloserLateRequestFromList($discloserListId: ID!, $organisationIds: [ID!], $deleteAll: Boolean!) {
    removeDiscloserLateRequestFromList(discloserListId: $discloserListId, organisationIds: $organisationIds, deleteAll: $deleteAll) {
      success
    }
  }
  `);

export const SUBMIT_A_DISCLOSER = gql(`
mutation SubmitDiscloserList($discloserListId: ID!) {
  submitDiscloserList(discloserListId: $discloserListId) {
    submittedOn
  }
}
`);

export const RESET_LIST = gql(`
mutation ResetList($resetListId: ID!) {
  resetList(id: $resetListId)
}`);

export const UPDATE_AUTHORITY_REF = gql(`
mutation UpdateAuthorityReference($discloserListId: ID!, $discloserListOrganisationId: ID!, $authorityReference: String) {
  updateAuthorityReference(discloserListId: $discloserListId, discloserListOrganisationId: $discloserListOrganisationId, authorityReference: $authorityReference) {
    warning
  }
}`);

export const UPDATE_DISCLOSER_LIST_ORGANISATION_THEMES = gql(`
mutation UpdateDiscloserListOrganisationThemes($discloserListId: ID!, $discloserListOrganisationId: ID!, $forestTheme: Boolean!, $waterTheme: Boolean!) {
  updateDiscloserListOrganisationThemes(discloserListId: $discloserListId, discloserListOrganisationId: $discloserListOrganisationId, forestTheme: $forestTheme, waterTheme: $waterTheme) {
    success
  }
}`);

export const UPDATE_DISCLOSER_EMAIL = gql(`
mutation UpdateDiscloserEmail($discloserListId: ID!, $discloserListOrganisationId: ID!, $discloserEmail: String) {
  updateDiscloserEmail(discloserListId: $discloserListId, discloserListOrganisationId: $discloserListOrganisationId, discloserEmail: $discloserEmail) {
    success
  }
}`);

export const UPDATE_ORG_DETAILS = gql(`
mutation UpdateOrganisationDetails($updateOrganisationInput: UpdateOrganisationInput) {
  updateOrganisationDetails(updateOrganisationInput: $updateOrganisationInput) {
    updated_on
    description
    website
    logoUrl
    orgHqPostalCode
    localOrgName
    orgHqAddressLine1
    orgHqCity
    OrgHqCountry {
      countryId
      countryName
    }
    activity {
      activityId
      activityName
    }
    activityGroup {
      activityGroupId
      activityGroupName
    }
  }
}
`);

export const UPDATE_DUNS_ORGANISATION = gql(`
mutation UpdateDunsDisclosureListOrganisation($disclosureListId: ID!, $organisationId: ID!, $duns: String!) {
updateDunsDisclosureListOrganisation(disclosureListId: $disclosureListId, organisationId: $organisationId, duns: $duns) {
    success
  }
}
`);

export const UPDATE_MY_DETAILS = gql(`
mutation UpdateMyDetails($updateMyDetailsInput: UpdateMyDetailsInput) {
  updateMyDetails(updateMyDetailsInput: $updateMyDetailsInput) {
    id
    jobTitle
    surname
    emailAddress
  }
}
`);

export const CREATE_TRACKING_PORTFOLIO = gql(`
mutation CreateTrackingPortfolio($discloserListId: ID!, $name: String!, $description: String!, $discloserListOrganisationIds: [ID!]!, $isFromSample: Boolean, $sampleId: ID) {
  createTrackingPortfolio(discloserListId: $discloserListId, name: $name, description: $description, discloserListOrganisationIds: $discloserListOrganisationIds, isFromSample: $isFromSample, sampleId: $sampleId) {
    created_on
    name
    description
    isFromSample
    trackingPortfolioId
  }
}
`);

export const REMOVE_TRACKING_PORTFOLIO = gql(`
mutation DeleteTrackingPortfolio($trackingPortfolioId: ID!) {
  deleteTrackingPortfolio(trackingPortfolioId: $trackingPortfolioId)
}
`);

export const UPDATE_TRACKING_PORTFOLIO = gql(`
mutation UpdateTrackingPortfolio($trackingPortfolioId: ID!, $name: String!, $description: String!, $discloserListOrganisationIds: [ID!]!) {
  updateTrackingPortfolio(trackingPortfolioId: $trackingPortfolioId, name: $name, description: $description, discloserListOrganisationIds: $discloserListOrganisationIds)
}
`);

export const CREATE_PROJECT = gql(`
mutation CreateProject($createProjectInput: CreateProjectInput!, $createProjectTranslationInput: [CreateProjectTranslationInput!]!) {
  createProject(createProjectInput: $createProjectInput, createProjectTranslationInput: $createProjectTranslationInput) {
    name
    mainAuthorityId
    projectId
    logoUrl
  }
}
`);

export const CREATE_A_USER_WITH_INVITE = gql(`
mutation CreateUserWithInvite($inviteToken: String!, $emailAddress: String, $firstName: String!, $lastName: String!, $password: String!, $repassword: String!, $jobTitle: String) {
  createUserWithInvite(inviteToken: $inviteToken, emailAddress: $emailAddress, firstName: $firstName, lastName: $lastName, password: $password, repassword: $repassword, jobTitle: $jobTitle) {
    result
  }
}
`);

export const CREATE_INVITATION = gql(`
mutation CreateInvitation($email: String!, $roles: [String!]!) {
  createInvitation(email: $email, roles: $roles) {
    email
  }
}
`);

export const UPDATE_USER_DETAILS = gql(`
mutation UpdateUserDetails($updateUserDetails: UpdateUserDetailsInput!) {
  updateUserDetails(updateUserDetails: $updateUserDetails) {
    displayName
    jobTitle
    givenName
    surname
    emailAddress
    roles
  }
}
`);

export const DISABLE_USER = gql(`
mutation DisableUser($disableUserInput: DisableUserInput) {
  disableUser(disableUserInput: $disableUserInput) {
    result
  }
}
`);

export const UPDATE_PROJECT = gql(`
mutation UpdateProject($updateProjectInput: UpdateProjectInput!, $updateProjectTranslationInput: [UpdateProjectTranslationInput!]) {
  updateProject(updateProjectInput: $updateProjectInput, updateProjectTranslationInput: $updateProjectTranslationInput) {
    updated_on
    updatedBy
    projectId
    name
    description
    mainAuthorityId
    logoUrl
  }
}
`);

export const DELETE_PROJECT = gql(`
mutation DeleteProject($projectId: String!) {
  deleteProject(projectId: $projectId) {
    created_on
    updated_on
    createdBy
    updatedBy
    projectId
    name
    description
    mainAuthorityId
    logoUrl
  }
}
`);

export const CREATE_PROJECT_INSTANCE = gql(`
mutation CreateProjectInstance($createProjectInstanceInput: CreateProjectInstanceInput!, $createProjectTranslationInput: [CreateProjectInstanceTranslationInput!]!) {
  createProjectInstance(createProjectInstanceInput: $createProjectInstanceInput, createProjectTranslationInput: $createProjectTranslationInput) {
    projectInstanceId
    projectInstanceType
    projectId
    name
    isPublished
    isPublic
    endDate
    disclosureCycleId
    description
    created_on
    createdBy
    updated_on
    startDate
    subAuthorityId
    projectManagerId,
    projectInstanceUrl
  }
}
`);

export const UPDATE_PROJECT_INSTANCE = gql(`
mutation UpdateProjectInstance($projectInstanceId: String!, $updateProjectInstanceInput: UpdateProjectInstanceInput!, $updateProjectInstanceTranslationInput: [UpdateProjectInstanceTranslationInput!]) {
    updateProjectInstance(projectInstanceId: $projectInstanceId, updateProjectInstanceInput: $updateProjectInstanceInput, updateProjectInstanceTranslationInput: $updateProjectInstanceTranslationInput) {
        projectInstanceId
    }
  }
  `);

export const DELETE_PROJECT_INSTANCE = gql(`
mutation DeleteProjectInstance($projectInstanceId: String!) {
  deleteProjectInstance(projectInstanceId: $projectInstanceId) {
    name
  }
}
`);

export const PUBLISH_PROJECT_INSTANCE = gql(`
mutation PublishProjectInstance($projectInstanceId: String!) {
  publishProjectInstance(projectInstanceId: $projectInstanceId) {
    name
  }
}
`);

export const UNPUBLISH_PROJECT_INSTANCE = gql(`
mutation UnpublishProjectInstance($projectInstanceId: String!) {
  unpublishProjectInstance(projectInstanceId: $projectInstanceId) {
    name
  }
}
`);

export const ADD_ORGANISATION_TO_PROJECT_INSTANCE = gql(`
mutation AddOrganisationToProjectInstance($addOrganisationToProjectInstanceInput: AddOrganisationToProjectInstanceInput!) {
  addOrganisationToProjectInstance(AddOrganisationToProjectInstanceInput: $addOrganisationToProjectInstanceInput) {
    projectInstanceId
    discloserListOrganisation {
      disclosingOrganisation {
        orgName
      }
    }
  }
}
`);

export const REMOVE_ORGANISATION_FROM_PROJECT_INSTANCE = gql(`
mutation RemoveOrganisationsFromProjectInstance($projectInstanceId: String!, $discloserListOrganisationIds: [String!]!, $authorityTypeId: String!) {
  removeOrganisationsFromProjectInstance(ProjectInstanceId: $projectInstanceId, DiscloserListOrganisationIds: $discloserListOrganisationIds, AuthorityTypeId: $authorityTypeId) {
    discloserListOrganisation {
      organisation {
        orgName
      }
    }
  }
}
`);

export const REMOVE_ORGANISATION_FROM_SAMPLE = gql(`
mutation DeleteOrganisationsFromSampleList($trackingPortfolioId: String!, $discloserListOrganisationIds: [String!]!) {
  deleteOrganisationsFromSampleList(trackingPortfolioId: $trackingPortfolioId, discloserListOrganisationIds: $discloserListOrganisationIds) {
    createdBy
  }
}
`);

export const CREATE_QUESTIONNAIRE_SETUP = gql(`
mutation CreateQuestionnaireSetup ($disclosureCycleId: String!) {
  createQuestionnaireSetup(disclosureCycleId: $disclosureCycleId){
    disclosureCycleId
    organisationId
    questionnaireSetupId
   }
}
`);

export const UPDATE_MY_LANGUAGE_PREFERENCES = gql(`
  mutation UpdateMyLanguagePreferences($updateMyLanguagePreferencesInput: UpdateMyLanguagePreferencesInput) {
  updateMyLanguagePreferences(updateMyLanguagePreferencesInput: $updateMyLanguagePreferencesInput) {
    id
  }
}
`);

export const UPDATE_QUESTIONNAIRE_SETUP = gql(`
mutation UpdateQuestionnaireSetup($questionnaireSetupId: String!, $updateQuestionnaireSetupInput: UpdateQuestionnaireSetupInput!) {
  updateQuestionnaireSetup(
    UpdateQuestionnaireSetupInput: $updateQuestionnaireSetupInput,
    questionnaireSetupId: $questionnaireSetupId){
      questionnaireSetupId
    organisationId
   }
}
`);

export const CREATE_A_SAMPLE = gql(`
mutation CreateSampleUsingOrganisationIds($sampleCreationInputModel: SampleCreationInputModel!) {
    createSampleUsingOrganisationIds(sampleCreationInputModel: $sampleCreationInputModel) {
      trackingPortfolioId
      discloserListId
    }
  }
`);

export const ADD_ORGANISATIONS_TO_TRACKING_PORTFOLIO = gql(`
mutation AddDiscloserListOrganisationsToTrackingPortfolio($trackingPortfolioId: ID!, $discloserListOrganisationIds: [ID!]!) {
  addDiscloserListOrganisationsToTrackingPortfolio(trackingPortfolioId: $trackingPortfolioId, discloserListOrganisationIds: $discloserListOrganisationIds)
}
`);

export const DELETE_SAMPLE = gql(`
mutation DeleteSample($trackingPortfolioId: String!) {
    deleteSample(trackingPortfolioId: $trackingPortfolioId) {
      trackingPortfolioId
    }
  }
`);

export const PUBLISH_SAMPLE = gql(`
mutation PublishSample($trackingPortfolioId: String!) {
    publishSample(trackingPortfolioId: $trackingPortfolioId) {
      trackingPortfolioId
    }
  }
`);

export const UNPUBLISH_SAMPLE = gql(`
mutation UnpublishSample($trackingPortfolioId: String!) {
    unpublishSample(trackingPortfolioId: $trackingPortfolioId) {
      trackingPortfolioId
    }
  }
`);

export const ADD_DUNS_TO_DISCLOSER_LIST = gql(`
mutation AddDunsOrgToDiscloserList($discloserListId: ID!, $organisations: [DnbOrganisationModelInput!]!) {
  addDunsOrgToDiscloserList(discloserListId: $discloserListId, organisations: $organisations) {
    success
  }
}
`);

export const UPDATE_SAMPLE = gql(`
mutation UpdateSample($trackingPortfolioId: String!, $updateSampleInputModel: SampleUpdateInputModel!) {
  updateSample(trackingPortfolioId: $trackingPortfolioId, updateSampleInputModel: $updateSampleInputModel) {
    name
  }
}
`);
export const UPDATE_ACTIVITY_REVENUE_PERCENTAGE = gql(`
mutation UpdateActivityRevenuePercentages(
  $questionnaireSetupId: String!,
  $activityRevenuePercentages:[UpdateActivityRevenuePercentageInput!]!
) {
  updateActivityRevenuePercentages(
    questionnaireSetupId: $questionnaireSetupId,
    activityRevenuePercentages: $activityRevenuePercentages,
  ) {
    questionnaireSetupId
    questionnaireSetupSectorAllocations{
      isPrimary
      questionnaireSector {
        questionnaireSectorId
        name
      }
    }
    }
  }
`);

export const CREATE_ORGANISATION_ACTIVITY_CLASSIFICATION = gql(`
mutation CreateOrganisationActivityClassification(
  $activityId: String!,
  $disclosureCycleId: String!
) {
  createOrganisationActivityClassification(
    activityId: $activityId,
    disclosureCycleId: $disclosureCycleId
  ) {
    organisationActivityClassificationId
    }
  }
`);

export const UPDATE_ORGANISATION_ACTIVITY_CLASSIFICATION = gql(`
mutation UpdateOrganisationActivityClassification(
  $activityId: String!,
  $organisationActivityClassificationId: String!
) {
  updateOrganisationActivityClassification(
    activityId: $activityId,
    organisationActivityClassificationId: $organisationActivityClassificationId
  ) {
    organisationActivityClassificationId
    }
  }
`);

export const DELETE_ORGANISATION_ACTIVITY_CLASSIFICATION = gql(`
mutation DeleteOrganisationActivityClassification(
  $organisationActivityClassificationId: String!,
) {
  deleteOrganisationActivityClassification(
    organisationActivityClassificationId: $organisationActivityClassificationId,
  ) {
    organisationActivityClassificationId
    }
  }
`);

export const UPDATE_COMMODITIES_DISCLOSURES = gql(`
mutation UpdateOrganisationDisclosureCommodities(
  $questionnaireSetupId: String!
  $questionnaireSetupCommodities: [UpdateQuestionnaireSetupCommodityInput!]!
) {
  updateOrganisationDisclosureCommodities(
    questionnaireSetupId: $questionnaireSetupId,
    questionnaireSetupCommodities: $questionnaireSetupCommodities
  ) {
    questionnaireSetupId
    }
  }
`);

export const QUESTIONNAIRE_SETUP_SUBMIT = gql(`
mutation TriggerQuestionnaireSetupSubmittedEvent(
  $questionnaireSetupId: String!
) {
  triggerQuestionnaireSetupSubmittedEvent(
    questionnaireSetupId: $questionnaireSetupId,
  ) {
    questionnaireSetupId
    }
  }
`);

export const UPDATE_CAN_ADD_CONTACTS = gql(`
mutation UpdateCanAddContacts($canAddContacts: Boolean!) {
  updateCanAddContacts(canAddContacts: $canAddContacts) {
    canAddContacts
  }
}
`);

export const SELECT_PRODUCT_ITEM = gql(`
mutation SelectProduct($productListItemId: String!, $disclosureCycleId: String!) {
  selectProduct(productListItemId: $productListItemId, disclosureCycleId: $disclosureCycleId) {
    invoiceId
    organisationId
    status
  }
}
`);

export const RAISE_INVOICE = gql(`
mutation RaiseInvoice(
    $invoiceId: String!,
    $billingInfo: BillingInfoInput!,
    $productInfo: ProductInfoInput,
  ) {
    raiseInvoice(
      productInfo: $productInfo,
      invoiceId: $invoiceId,
      billingInfo: $billingInfo,
    ) {
      invoiceId
      organisationId
      status
      totalInvoiceAmount
      invoiceTaxPercentage
      totalTaxAmount
      billingEmailAddress
      billingFirstName
      billingLastName
      billingCompanyName
      billingAddressLine1
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
      purchaseOrderNumber,
      accountInternalReference,
      billingEuVatNo
    }
  }
`);

export const UPDATE_INVOICE_BILLING_INFO = gql(`
mutation UpdateBillingInformation(
    $invoiceId: String!,
    $billingInfo: BillingInfoInput!,
    $status: String,
    $productInfo: ProductInfoInput,
  ) {
    updateBillingInformation(
      invoiceId: $invoiceId,
      billingInfo: $billingInfo,
      status: $status,
      productInfo: $productInfo,
    ) {
      invoiceId
      organisationId
      status
      totalInvoiceAmount
      invoiceTaxPercentage
      totalTaxAmount
      billingEmailAddress
      billingFirstName
      billingLastName
      billingCompanyName
      billingAddressLine1
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
    }
  }
`);

export const PAY_INVOICE = gql(`
mutation PayInvoice(
    $invoiceId: String!,
    $transactionId: String,
  ) {
    payInvoice(
      invoiceId: $invoiceId,
      transactionId: $transactionId,
    ) {
      invoiceId
      organisationId
      status
      totalInvoiceAmount
      invoiceTaxPercentage
      totalTaxAmount
      billingEmailAddress
      billingFirstName
      billingLastName
      billingCompanyName
      billingAddressLine1
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
    }
  }
`);

export const UPDATE_INVOICE_ON_FAILURE = gql(`
mutation UpdateInvoiceOnFailure(
    $invoiceId: String!,
    $transactionId: String,
  ) {
    updateInvoiceOnFailure(
      invoiceId: $invoiceId,
      transactionId: $transactionId,
    ) {
      invoiceId
      organisationId
      status
      totalInvoiceAmount
      invoiceTaxPercentage
      totalTaxAmount
      billingEmailAddress
      billingFirstName
      billingLastName
      billingCompanyName
      billingAddressLine1
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
      purchaseOrderNumber
      accountInternalReference
      billingEuVatNo
    }
  }
`);

export const JOIN_OPTIONAL_PROJECT = gql(`
mutation JoinOptionalProject($importedProjectInstanceOrganisationId: String!) {
  joinOptionalProjectInstanceFromRequest(importedProjectInstanceOrganisationId: $importedProjectInstanceOrganisationId) {
  importedProjectInstanceOrganisationId
}
}
`);

export const DELEGATE_CHILD_REQUEST_TO_PARENT = gql(`
mutation DelegateRequest($organisationId: ID!, $disclosureCycleId: String!) {
  delegateRequest(organisationId: $organisationId, disclosureCycleId: $disclosureCycleId)
}
`);

export const SET_RESPONSE_MAPPING = gql(`
mutation SetResponseMapping($organisationId: ID!, $disclosingOrgId: ID!, $disclosureCycleId: String!) {
    setResponseMapping(organisationId: $organisationId, disclosingOrgId: $disclosingOrgId, disclosureCycleId: $disclosureCycleId)
  }
`);

export const UPDATE_RESPONSE_EXTENSION = gql(`
mutation UpdateResponseExtension($responseId: String!, $extensionWeeks: Float!, $extensionReason: String!) {
    updateResponseExtension(responseId: $responseId, extensionWeeks: $extensionWeeks, extensionReason: $extensionReason){
      responseId
      extensionWeeks
      extensionReason
    }
  }
`);
